<template>
  <b-container fluid style="background-color: #fff" class="pb-3">
    <b-row>
      <div class="iq-top-navbar">
        <div class="iq-navbar-custom">
          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="iq-navbar-logo d-flex justify-content-between">
              <img :src="orgData.org_profile_img" class="img-fluid navBarLogo ml-2 mt-3" alt="logo">
            </div>
          </nav>
        </div>
      </div>
    </b-row>
    <b-row>
      <b-col md="12" lg="8" class="" v-if="operatingsystem == 'web'">
        <iq-card class="p-4 banner-text-left-card">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <img :src="orgData.org_cover_img" style="width:100%" />
            <!-- Event Description -->
            <div class="col-12 mt-4">
            </div><!-- Event Description -->
          </template>
        </iq-card>
      </b-col>
      <b-col lg="4" style='background-color:#fff'>
        <div class="p-3">
          <h3>{{ title }}</h3>
          <h5 class="primary-color">{{ pay_tid ? "Payment Id - " + pay_tid : "" }}</h5>
          <h5 class="mt-2" v-html="message"></h5>
          <div class="sign-info">
            <span class="dark-color d-inline-block line-height-2">Already Have An Account ? <router-link :to="{ name: 'auth.login'}">Sign In</router-link></span>
            <ul class="iq-social-media mt-4">
              <li>
                <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
              </li>
              <li>
                <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
              </li>
              <li>
                <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
              </li>
            </ul>
          </div>
         </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { socialvue } from "../config/pluginInit"
export default {
  name: "Confirmation",
  data: () => ({
    pay_tid: "",
    title: "Thank You",
    message: `Thank you for registering on GIDE.AI. Please check your email for the One-Time Password (OTP) to Proceed. <a class="pointer primary-color" href="${process.env.VUE_APP_SERVER_URL}/activate">Click Here</a> to verify OTP.<br><br>If you don't see it, remember to check your Spam folder.<br>If you are already logged in, please refresh your browser tab<br><br>Need help? Contact <a class="pointer primary-color" href="mailto:support@gide.ai">support@gide.ai</a>`,
    orgData: {
      org_profile_img: "https://web.gide.ai/img/GideWhiteNRed.2533ee45.jpeg",
      org_cover_img: "https://gide-assets.s3.amazonaws.com/GIDECover.White.png"
    },
    operatingsystem: window.__DEVICE_OS__,
    propOpenedInModal: false
  }),
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    socialvue.index()
    let code = this.$route.query.code ? this.$route.query.code : ""
    let payTid = this.$route.query.pay_tid ? this.$route.query.pay_tid : ""

    switch (code) {
      case "REGISTERATION_DONE":
        break

      case "PAYMENT_DONE":
        this.title = "Payment Received"
        this.pay_tid = payTid
        break

      default:
    }
  },
  methods: {
  }
}
</script>
